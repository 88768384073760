import { useEffect, useState, useMemo, useContext } from 'react';
import { endpoint } from '../../utils/endpoint'
import HeroCard from '../../Components/HeroCard/HeroCard';
import LanguageContext from '../../contexts/LanguageContext'
import './Hero.css'


const Hero = ({ language, wspLink }) => {

    const { title1, sub1, title2, sub2, title3, sub3, title4, sub4, button, cards } = language.hero;
    const url = `${endpoint}${process.env.PUBLIC_URL}/assets`;
    const imagesObj = {
        s1: `${url}/h1.webp`,
        s2: `${url}/online.webp`,
        s3: `${url}/h2.webp`,
        s4: `${url}/h3.webp`,
    };
    const { s1, s2, s3, s4 } = imagesObj;
    const images = useMemo(() => [s1, s2, s3, s4], [s1, s2, s3, s4]);
    const titles = [title1, title2, title3, title4];
    const subtitles = [sub1, sub2, sub3, sub4];
    const [currentImage, setCurrentImage] = useState(images[0]);
    const context = useContext(LanguageContext);
    const [counter, setCounter] = useState(0);

    useEffect(() => {

        setCurrentImage(images[counter]);
        const intervalId = setInterval(() => {
            if (counter >= images.length - 1) setCounter(0);
            else setCounter(counter + 1);
        }, 4500)
        return () => clearInterval(intervalId);
    }, [counter, images])

    const onContactClick = () => {
        window.open(wspLink)
    }

    return (
        <>
            <div className='Hero flex-center' id={language.menu[0][1]} style={{ backgroundImage: `url(${currentImage})`, transition: 'all 0.6s ease' }}>
                <div className="bg-overlay"></div>
                <h1 data-aos="zoom-in-down" data-aos-duration="2000">{titles[counter]}</h1>
                <p data-aos="fade-up" data-aos-duration="2500">{subtitles[counter]}</p>
                <button data-aos="fade-up" className={`btnGradient contactBtn ${context.language}`} data-aos-duration="2500" onClick={e => onContactClick()}>{button}</button>
            </div>
            <div className="Cards flex-center">
                {cards.map(card => <HeroCard key={card.num} num={card.num} title={card.title}
                    desc={card.desc} color={card.color} />)}
            </div>
        </>

    )
}

export default Hero