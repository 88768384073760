import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import {getIcon} from '../../utils/iconGetter'

export const NavItems = ({menu, txtAlign}) => {
  const { theme } = useContext(ThemeContext);
  return (
<ul style={{textAlign:txtAlign}}>
      {menu.map((item,i) => (
        <li key={i}><a href={`#${item[1]}`} style={{color:theme.text}}>{getIcon(item[1])}{item[0]}</a></li>
      ) )}
    </ul>
  )
}