import './ClientFavicon.css';
import { endpoint } from '../../utils/endpoint';

const ClientFavicon = ({ name, website, favicon, style }) => {
  return (
    <div className='ClientFavicon' style={style}>
      <div className="avatar">
        <a href={website} target="_blank" rel="noreferrer">
          <img src={`${endpoint}${favicon}`} alt={name} />
        </a>
      </div>
    </div>
  );
}

export default ClientFavicon;
