import React, { useState } from 'react';
import './Accessibility.css'
import { AccessibilityNew } from '@material-ui/icons';


const Accessibility = ({language}) => {

    const {AccessibilityBtns} = language

    const [isVisible, setIsVisible] = useState(false);
    const [isGrayscale, setIsGrayscale] = useState(false);
    const [isContrast, setIsContrast] = useState(false);
    const [isUnderlineLinks, setIsUnderlineLinks] = useState(false);
    const [isNormalFont, setIsNormalFont] = useState(false);
    const [isInverted, setIsInverted] = useState(false);



    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const increaseFontSize = () => {
        document.body.style.fontSize = 'larger';
    };
    const decreaseFontSize = () => {
        document.body.style.fontSize = 'smaller';
    };
    const toggleGrayscale = () => {
        setIsGrayscale(!isGrayscale);
        document.body.style.filter = isGrayscale ? 'none' : 'grayscale(100%)';
    };
    const toggleContrast = () => {
        setIsContrast(!isContrast);
        document.body.style.filter = isContrast ? 'none' : 'contrast(200%)';
    };
    const toggleUnderlineLinks = () => {
        setIsUnderlineLinks(!isUnderlineLinks);
        const links = document.querySelectorAll('a');
        links.forEach(link => {
            link.style.textDecoration = isUnderlineLinks ? 'none' : 'underline';
        });
    };
    const toggleNormalFont = () => {
        setIsNormalFont(!isNormalFont);
        document.body.style.fontFamily = isNormalFont ? 'sans-serif' : 'initial';
    };
    const toggleInverted = () => {
        setIsInverted(!isInverted);
        document.body.style.filter = isInverted ? 'none' : 'invert(100%)';
    };
    const resetAll = () => {
        setIsGrayscale(false);
        setIsContrast(false);
        setIsUnderlineLinks(false);
        setIsNormalFont(false);
        setIsInverted(false);
        document.body.style.filter = 'none';
        document.body.style.fontFamily = 'initial';
        const links = document.querySelectorAll('a');
        links.forEach(link => {
            link.style.textDecoration = 'none';
        });
    };


    return (
        <div className="accessibility-options">
            <button className='accessibility-btn' onClick={toggleVisibility}><AccessibilityNew /></button>
            <div className={`accessibility-menu${isVisible ? ' visible' : ''}`}>
                <button onClick={toggleContrast}>{AccessibilityBtns[0]}</button>
                <button onClick={toggleInverted}>{AccessibilityBtns[1]}</button>
                <button onClick={toggleGrayscale}>{AccessibilityBtns[2]}</button>
                <button onClick={increaseFontSize}>{AccessibilityBtns[3]}</button>
                <button onClick={decreaseFontSize}>{AccessibilityBtns[4]}</button>
                <button onClick={toggleUnderlineLinks}>{AccessibilityBtns[5]}</button>
                <button onClick={toggleNormalFont}>{AccessibilityBtns[6]}</button>
                <button onClick={resetAll}>{AccessibilityBtns[7]}</button>
            </div>
        </div>
    );
}

export default Accessibility
