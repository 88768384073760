import React, { useState } from 'react';
// import { endpoint } from '../../utils/endpoint'
import "./SvcForm.css"

const MonthlyPlanningForm = () => {
    const [review, setReview] = useState('');
    const [goals, setGoals] = useState('');
    const [focusPromotions, setFocusPromotions] = useState('');
    const [content, setContent] = useState('');
    const [feedback, setFeedback] = useState('');
    const [actionItems, setActionItems] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        console.log({
            review,
            goals,
            focusPromotions,
            content,
            feedback,
            actionItems
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='question'>
                <label>
                    مراجعة:
                    <textarea
                        placeholder='الإنجازات، التحديات'
                        value={review}
                        onChange={(e) => setReview(e.target.value)}
                    />
                </label>
            </div>
            <div className='question'>
                <label>
                    الأهداف:
                    <textarea
                        placeholder='الأهداف الرئيسية لهذا الشهر'
                        value={goals}
                        onChange={(e) => setGoals(e.target.value)}
                    />
                </label>
            </div>
            <div className='question'>
                <label>
                    التركيز/العروض:
                    <textarea
                        placeholder='التركيزات المحددة، العروض، المواضيع الرئيسية'
                        value={focusPromotions}
                        onChange={(e) => setFocusPromotions(e.target.value)}
                    />
                </label>
            </div>
            <div className='question'>
                <label>
                    المحتوى:
                    <textarea
                        placeholder='أنواع المحتوى المفضلة، المواضيع'
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                </label>
            </div>
            <div className='question'>
                <label>
                    التغذية الراجعة:
                    <textarea
                        placeholder='التحسينات، تفضيلات التعاون'
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                </label>
            </div>
            <div className='question'>
                <label>
                    العناصر التنفيذية:
                    <textarea
                        placeholder='ملخص عناصر التنفيذ المتفق عليها'
                        value={actionItems}
                        onChange={(e) => setActionItems(e.target.value)}
                    />
                </label>
            </div>
            <button type="submit">إرسال</button>
        </form>
    );
};

export default MonthlyPlanningForm;
