import './Navbar.css'
import {endpoint} from '../../utils/endpoint'
import React, { useContext, useState } from 'react';
import { RiMenuLine, RiCloseLine } from 'react-icons/ri'
import { FaArrowAltCircleUp } from 'react-icons/fa'
import { NavItems } from './NavItems'
import { useEffect } from 'react/';
import { useNavigate } from 'react-router-dom'
import ThemeContext from '../../contexts/ThemeContext';
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';


const Navbar = ({ language, txtAlign, dir}) => {

    const { menu } = language;
    let [toggleMenu, setToggleMenu] = useState(false);
    let [toggleScrUp, setToggleScrUp] = useState(false);
    const { theme } = useContext(ThemeContext);
    let y = window.scrollY;
    const navigate = useNavigate();
    const logo = theme.mode === 'light' ? 'logo-light.svg' : 'logo-dark.svg'

    useEffect(() => {
        window.addEventListener("scroll", (e) => {
            setToggleMenu(false)
            window.scrollY > 800 ? setToggleScrUp(true) : setToggleScrUp(false)
        });
    }, [y])

    return (

        <nav className='Navbar' style={{ backgroundColor: theme.cardsBg, direction: 'dir'}}>
            <ThemeSwitcher />
            {toggleScrUp && <a className="scroll-up" href={`#${menu[0]}`}><FaArrowAltCircleUp size={40} /></a>}
            <div className='logo-wrapper' onClick={() => navigate('/')}><img src={`${endpoint}${logo}`} alt="QB Media" className="logo"
                data-aos="fade-right" data-aos-duration="1500" /></div>
            <div className="links">
                <NavItems menu={menu} txtAlign={txtAlign} />
            </div>

            <div className="mobile-nav">
                {!toggleMenu
                    ? <RiMenuLine size={30} onClick={() => setToggleMenu(true)}
                        data-aos="fade-left" data-aos-duration="1500" />
                    : <RiCloseLine size={30} onClick={() => setToggleMenu(false)}
                        data-aos="zoom-in" data-aos-duration="500" />
                }

                <div className={`mobile-links bgc-white ${toggleMenu ? 'fade-in-top' : 'fade-in-bottom'}`}
                style={{backgroundColor:theme.cardsBg}}>
                    <NavItems menu={menu} />
                </div>

            </div>
        </nav>

    )
}

export default Navbar