import './Services.css'
import {endpoint} from '../../utils/endpoint'
import { AiFillCheckCircle } from 'react-icons/ai';
import { SectionHeading, ServiceCard } from '../../Components'
import { igLink } from '../../utils/links';


const Services = ({ language, dir }) => {
    const { sh2, features, p, wh2, cards,s,s1,s2,s3 } = language.services;
    
    return (

        <div className='Services flex-center section' style={{direction:'rtl'}}>
            <h2 className='sectionTitle st-ar'>{sh2}
        <div className="slider-wrapper">
        <p style={{marginLeft:'5px'}}>{ dir === 'ltr' && s}</p>
          <div className="slider">
              <div className="slider-text1 s-ar">{s1}</div>
              <div className="slider-text2 s-ar">{s2}</div>
              <div className="slider-text3 s-ar">{s3}</div>
          </div>
          <p>{ dir === 'rtl' && s}</p>
        </div>       
            </h2>


            <div className="image-and-features">
                <img onClick={() => window.open(igLink,'_blank')} src={`${endpoint}images/qbmedia-instagram-n.webp`} className="igimg" alt="קיובי מדיה"
                    data-aos="fade-right" data-aos-duration="2000" />
                <div className="features">
                    {features.map((feature, i) => (
                        <span key={i} className="feature"
                            data-aos="fade-left" data-aos-duration={`${feature.speed}`}>
                            {feature.title} <AiFillCheckCircle size={30} /></span>
                    ))}
                </div>
            </div>


            <SectionHeading p={p} h2={wh2} />
            <div className="cards">
                {cards.map((card, i) => (
                    <ServiceCard key={i} icon={card.icon} title={card.title}
                        desc={card.description} dir={card.dir} />
                ))}
            </div>
        </div>

    )
}

export default Services