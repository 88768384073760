import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import './HeroCard.css'


const HeroCard = ({num,title,desc,color}) => { 

    const { theme } = useContext(ThemeContext);

return(

<div className='HeroCard' style={{borderBottom:`3px solid ${color}`, backgroundColor:theme.cardsBg}}
data-aos={`fade-up`} data-aos-duration="1500">
    <span className="num">{num}</span>
<h2>{title}</h2>
<p>{desc}</p>
</div>

)
}

export default HeroCard