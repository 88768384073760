import './PlanCard.css';
import PricingContext from '../../contexts/PricingContext';
import { useContext } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import { $Rate } from '../../utils/links';


const PlanCard = ({dir, txtAlign, cur, card: { title, price, features }, plan: { tax, disclaimer, inquiry, customPlan, btnMsg, monthly, yearly }, planTypes, wspLink }) => {
    const { pricingPlan, currentPlan, setCurrentPlan } = useContext(PricingContext);
    const { theme } = useContext(ThemeContext);
    const planName = planTypes.find(p => p.type === currentPlan)['name'];
    const UAEPrice = Math.ceil(Number((price / $Rate).toFixed(0)) / 50) * 50;
    const checkoutLng = cur === '$' ? '?lng=en&currency_code' : '?lng=he'
    const buylink = `https://app.icount.co.il/m/a76ab/c110081672p30u644fa${checkoutLng}&utm_source=iCount&utm_medium=paypage&utm_campaign=30&cd=${planName} - ${title} - ${pricingPlan === 'monthly' ? monthly : yearly}`
    const monthlyLink = `${buylink}&cs=${cur === '₪' ? price : UAEPrice}`
    const yearlyLink =  `${buylink}&cs=${(cur === '₪' ? (price * 11.5).toFixed(0) : ((price * 11.5)/$Rate).toFixed(0))}`

    return (
        <div className={`PlanCard`} data-aos={`zoom-in`} data-aos-duration="1500"
            style={{ backgroundColor: theme.cardsBg }}>
            <div className={`custom-plans`} style={{direction:'rtl'}}>
                {planTypes.map(plan => (
                    <div key={plan.type} className={`custom-plan ${plan.type[0]} fz07rem ${currentPlan === plan.type && 'active'}`} id={plan.type} onClick={e => (setCurrentPlan(e.target.id))}>
                        {plan.name}
                    </div>
                ))}
            </div>
            <p className='plan-title'>{title}</p>
            <h2 className={`plan-price`}>
                <span className='currency'>{cur === "$" ? '$' : '₪'}</span>
                {pricingPlan === 'monthly' ? (cur === "₪" ? price : UAEPrice) : (cur === "₪" ? (price * 11.5).toFixed(0) : (price * 11.5/$Rate).toFixed(0))}
                <span className={`plan-period`}>{pricingPlan === 'monthly' ? monthly : yearly}</span>
                {cur === "$" ? "" : <span className={`tax`}>{tax}</span>}
            </h2>
            <ul className={`features-list`} style={{textAlign:txtAlign, direction:dir}}>
                {features.map((feature, i) => (
                    <li key={i} className={`plan-feature`}>{feature}</li>
                ))}
            </ul>
            {
                currentPlan === "both" || currentPlan === "socialmedia" ? (
                    <>
                        <span className='plan-alert'>{disclaimer}</span>
                        <a href={`${wspLink}`} className='get-offer'>{inquiry}</a>
                    </>
                ) : <a href={`${wspLink}`} className='get-offer'>{customPlan}</a>
            }
            <button onClick={() => window.open(pricingPlan === 'monthly' ? monthlyLink : yearlyLink, '_blank')} className='scale-in-center btnGradient sub-btn' style={{ backgroundColor: theme.mode === "light" ? theme.text : theme.qb }}>
                    {btnMsg}
            </button>
        </div>
    )
}

export default PlanCard;