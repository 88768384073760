import './ImgCard.css'


const ImgCard = () => { 

return(

<div className='ImgCard'>
<h1>ImgCard</h1>
</div>

)
}

export default ImgCard