import {createContext,useState} from 'react';

const ThemeContext = createContext();
export const ThemeProvider = ({children}) => {
  const [theme,setTheme] = useState({
          mode:"light",
          bodyBg:"#F6F7F7",
          cardsBg:"#fff",
          cardsBgHover:"#2F3192",
          text:"#2F3192",
          qb:"#F47A1B",
        });


  const changeTheme = () => {
    if(theme.mode === 'light'){
        setTheme({
          mode:"dark",
          bodyBg:"#323233",
          cardsBg:"#1E1E1E",
          cardsBgHover:"#171717",
          text:"#fff",
          qb:"#F47A1B",
        })
    }else{
        setTheme({
          mode:"light",
          bodyBg:"#F6F7F7",
          cardsBg:"#fff",
          cardsBgHover:"#2F3192",
          text:"#2F3192",
          qb:"#F47A1B",
          logo:"qbmedia-slogan.webp"
        })
    }
  }
  

  return <ThemeContext.Provider value={{
    theme,
    setTheme,
    changeTheme,
    }}>
{children}
    </ThemeContext.Provider>
}

export default ThemeContext