import { Hero, Services, Whatwedo, Tools, Plans, Clients, Whorwe, Contact, Footer } from "../Sections"
import { Accessibility, Bubbles, Navbar } from "../Components"
import BottomCircleMenu from "../Components/BottomCircleMenu/BottomCircleMenu"
import { ilWspLink, uaeWspLink } from "../utils/links";



const Home = ({ phone, lng, font, dir, txtAlign, cur }) => {

    const metaDescription = lng.metaDescription;
    const title = lng.title;
    let wspLink = cur === "$" ? uaeWspLink : ilWspLink

    return (

        <div className={`Home ${font}`}>
            <meta name="description" content={metaDescription} />
            <title>{title}</title>
            <Bubbles />
            <Accessibility language={lng} />
            <BottomCircleMenu phone={phone} lng={lng} cur={cur} />
            <Navbar language={lng} txtAlign={txtAlign} dir={dir} />
            <Hero language={lng} wspLink={wspLink} />
            <Services language={lng} dir={dir} />
            <Whatwedo language={lng} dir={dir}/>
            <Tools language={lng} txtAlign={txtAlign} />
            <Plans language={lng} txtAlign={txtAlign} lngCode={font} dir={dir} cur={cur} wspLink={wspLink} />
            <Clients language={lng} dir={dir} font={font} />
            <Whorwe language={lng} />
            <Contact language={lng} cur={cur} txtAlign={txtAlign} dir={dir} />
            <Footer language={lng} cur={cur} />
        </div>

    )
}

export default Home