import { useContext, useState, useEffect } from 'react';
import { endpoint } from '../../utils/endpoint';
import LanguageContext from '../../contexts/LanguageContext';
import enLang from '../../languages/english.json'
import { Link } from 'react-router-dom';
import './LngSelect.css';
import { SocialIcons } from '../../Components';

const LngSelect = ({ lng }) => {
  const { changeLang } = useContext(LanguageContext);
  const [inIsrael, setInIsrael] = useState(null); // default to null until API call is complete

  const metaDescription = enLang.metaDescription;
  const title = enLang.title;

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        if (data.country === 'IL') {
          setInIsrael(true);
        } else {
          setInIsrael(false);
        }
      })
      .catch(error => console.log(error));
  }, []);

  // Render null until the API call is complete and the country is determined
  if (inIsrael === null) {
    return null;
  }

  // Render the appropriate language button based on the country
  return (
    <div className='LngSelect cwhite' style={{ backgroundImage: `url(${endpoint}assets/intro2.webp)` }}>
      <div className="bg-overlay"></div>
      <img src={`${endpoint}assets/footer-dark-logo.svg`} alt="QB Media" />
      <SocialIcons language={lng} />
      <div className="row">
        {inIsrael && (
          <>
            <Link to="/he" className="hebrew" onClick={() => changeLang('he')} data-aos={`fade-left`} data-aos-duration="1250">עברית</Link>
            <Link to="/ar" className="arabic" onClick={() => changeLang('ar')} data-aos={`fade-right`} data-aos-duration="1250">عربي</Link>
          </>
        )}
        {!inIsrael && (
          <>
            <Link to="/ae" className="arabic" onClick={() => changeLang('ar')} data-aos={`fade-right`} data-aos-duration="1250">عربي</Link>
            <Link to="/en" className="english" onClick={() => changeLang('en')} data-aos={`fade-left`} data-aos-duration="1250">English</Link>
          </>
        )}
      </div>
      <meta name="description" content={metaDescription} />
      <title>{title}</title>
    </div>
  );
};

export default LngSelect;
