import { ContactForm, SocialIcons } from '../../Components'
import {endpoint} from '../../utils/endpoint'
import { AiFillMessage } from "react-icons/ai"
import {MyMap} from "./Map"
import './Contact.css'


const Contact = ({ language, txtAlign, dir, cur}) => {
    const { p, h2, bg } = language.contact;
    return (

        <div className='Contact flex-center section cwhite' style={{ backgroundImage: `url(${endpoint}${bg})`, direction:dir}} 
        id={language.menu[4][1]}>
            <div className="bg-overlay"></div>
            <AiFillMessage className='sms' size="45" />
            <h2 className='cwhite'>{p}</h2>
            <p className='cwhite'>{h2}</p>
            <SocialIcons cur={cur} />
            <div className="form-container">
                <ContactForm language={language} txtAlign={txtAlign} />
                <div className="map" style={{margin: dir === 'rtl' ? '25px 50px 0 0' : '25px 0 0 50px'}}>
                <MyMap cur={cur} />
                </div>
            </div>
        </div>

    )
}

export default Contact