import React from 'react';
import {endpoint} from '../../utils/endpoint'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Star } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    gridContainer: {
        width:'auto',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: -theme.spacing(1),
    },
    gridItem: {
        padding: theme.spacing(1),
        width: 'calc(33.33% - 2 * 8px)',
        minHeight: '200px',
        marginBottom: '20px'
    },
    card: {
        width: '400px',
        height:'250px',
        overflow: 'auto',
        margin:'10px',
    },
    title: {
        textAlign: 'left',
        fontWeight: 'bold',
        paddingLeft:'8px'
    },
    cardContent: {
        overflow: 'auto',
        padding: theme.spacing(2),
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.5)',
            borderRadius: '10px',
        },
    },
    rating: {
        display: 'flex',
        alignItems: 'center',
    },
    star: {
        color: '#F47A1A',
        margin: '0 2px',
        marginRight: theme.spacing(1),
    },
    translation:{
        fontSize:'0.7rem',
        marginTop:'10px',
        color:'gray'
    }
}));

const ReviewCard = ({ review,translate }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.gridContainer}>
                <Grid key={'index'} item xs={12} sm={6} md={4} className={`gridItem`}>
                    <Card className={classes.card}>
                        <CardHeader
                            avatar={<Avatar src={`${endpoint}${review.profile_photo_url}`} className={classes.avatar} />}
                            title={review.author_name}
                            titleTypographyProps={{ className: classes.title }}
                            className={classes.title}
                            subheader={
                                <div className={classes.rating}>
                                    {Array.from({ length: review.rating }, (_, i) => (
                                        <Star key={i} className={classes.star} />
                                    ))}
                                </div>
                            }
                        />
                        <div className={classes.cardContent}>
                            <Typography variant="body2">{review.text}</Typography>
                            <Typography className={classes.translation}>{translate}</Typography>
                        </div>
                    </Card>
                </Grid>
        </Grid>
    );
};



export default ReviewCard;
