export const igLink = 'https://instagram.com/qbmedia.co'
export const fbLink = 'https://facebook.com/qbmedia.co'
// il
export const ilWspLink = 'https://wa.me/972529921100'
export const ilNum = '972529921100'
// uae
export const uaeWspLink = 'https://wa.me/971581818006'
export const uaeNum = '971581818006'
export const $Rate = 1.8
export const AEDRate = 0.595
