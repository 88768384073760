import { useContext, useState } from 'react';
import { ClientFavicon, SectionHeading, Swiper } from '../../Components';
import ThemeContext from '../../contexts/ThemeContext';
import './Clients.css';

const Clients = ({ language, font }) => {
  const { p, h2, clients, types } = language.clients;
  const [filter, setFilter] = useState(null);
  const { theme } = useContext(ThemeContext);

  const filteredClients = filter ? clients.filter((client) => client.type === filter) : clients;
  const faviconsHeight = filteredClients.length <= 6 ? '35vh' : '65vh';
  const faviconsWidth = filteredClients.length <= 5 ? '85vw' : '100vw';

  const generateRandomPlacement = () => {
    const minPositionX = 0; // Minimum position value for X-axis
    const maxPositionX = 80; // Maximum position value for X-axis
    const minPositionY = 10; // Minimum position value for Y-axis
    const maxPositionY = 80; // Maximum position value for Y-axis
  
    const positionX = Math.floor(Math.random() * (maxPositionX - minPositionX + 1)) + minPositionX;
    const positionY = Math.floor(Math.random() * (maxPositionY - minPositionY + 1)) + minPositionY;
  
    return { left: `${positionX}%`, top: `${positionY}%` };
  };
  

  const handleFilterClick = (type) => {
    setFilter(type);
  };

  return (
    <div className='Clients flex-center section' id={language.menu[3][1]} style={{ direction: 'rtl' }}>
      <SectionHeading p={p} h2={h2} />
      <div className={`websites-type ${font}`}>
        <button onClick={() => handleFilterClick(null)} className={filter === null ? `active ${font}` : `${font}`}>{types[0]}</button>
        <button onClick={() => handleFilterClick('menu')} className={filter === 'menu' ? `active ${font}` : `${font}`}>{types[1]}</button>
        <button onClick={() => handleFilterClick('store')} className={filter === 'store' ? `active ${font}` : `${font}`}>{types[2]}</button>
        <button onClick={() => handleFilterClick('svc-web')} className={filter === 'svc-web' ? `active ${font}` : `${font}`}>{types[3]}</button>
      </div>


      <div className="favicons" style={{ height: faviconsHeight,width:faviconsWidth }}>
        {filteredClients.map((client, i) => (
          <ClientFavicon
            key={i}
            name={client.name}
            website={client.website}
            favicon={client.favicon}
            style={generateRandomPlacement()}
          />
        ))}
      </div>

      <div className="clients-slider" style={{ backgroundColor: theme.cardsBg }} id='4'>
        <Swiper slides={filteredClients} />
      </div>
    </div>
  );
};

export default Clients;
