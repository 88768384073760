import {getIcon} from '../../utils/iconGetter'
import { ilWspLink, uaeWspLink } from '../../utils/links'

const SocialIcons = ({cur}) => { 

return(

<div className="social-icons flex-center" >
    <a className="icon" href={"https://www.instagram.com/qbmedia.co/"} target="_blank"
    rel="noreferrer" style={{backgroundColor:"#B4307D"}}>
        {getIcon("IG")}
    </a>
    <a className="icon" href={"https://www.facebook.com/qbmedia.co/"} target="_blank"
    rel="noreferrer" style={{backgroundColor:"#3C5998"}}>
        {getIcon("FB")}
    </a>
    <a className="icon" href={cur === "$" ? uaeWspLink : ilWspLink} target="_blank"
    rel="noreferrer" style={{backgroundColor:"#25D366"}}>
        {getIcon("WSP")}
    </a>
</div>

)
}

export default SocialIcons