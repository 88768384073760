import './App.css';
import { useContext } from 'react';
import { LngSelect } from "./Components"
import LanguageContext from './contexts/LanguageContext'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import heLang from './languages/hebrew.json'
import arLang from './languages/arabic.json'
import enLang from './languages/english.json'
import Home from './pages/Home';
import ArSvcForm from './Components/SvcForm/ArSvcForm';
import MonthlyPlanningForm from './Components/SvcForm/MonthlyPlanningForm';
import HeSvcForm from './Components/SvcForm/HeSvcForm';
import ThemeContext from './contexts/ThemeContext';
import { ilNum, uaeNum } from './utils/links';



function App() {

  AOS.init({
    offset: 200,
  });

  const { language } = useContext(LanguageContext);
  const { theme } = useContext(ThemeContext);
  document.body.style.backgroundColor = theme.bodyBg

  let lng = language === 'ar' ? arLang : heLang

  return (

    <div className={`App ${language}`} style={{color:theme.text}}>
      <Router>
        <Routes>
        <Route index element={<LngSelect lng={lng} />} />
        <Route path={`/he`} element={<Home phone={ilNum} lng={heLang} cur="₪" font={'he'} dir={'rtl'} txtAlign={'right'} />} />
        <Route path={`/ar`} element={<Home phone={ilNum} lng={arLang} cur="₪" font={'ar'} dir={'rtl'} txtAlign={'right'} />} />

        <Route path={`/ae`} element={<Home phone={uaeNum} lng={arLang} cur="$" font={'ar'} dir={'rtl'} txtAlign={'right'} />} />
        <Route path={`/en`} element={<Home phone={uaeNum} lng={enLang} cur="$" font={'ar'} dir={'ltr'} txtAlign={'left'} />} />
        {/* add heb lang for form */}
        <Route path={`ar/form`} element={<ArSvcForm lng={arLang} font={'ar'} dir={'rtl'} txtAlign={'right'}/>} />
        <Route path={`ar/monthlyform`} element={<MonthlyPlanningForm lng={arLang} font={'ar'} dir={'rtl'} txtAlign={'right'}/>} />
        <Route path={`he/form`} element={<HeSvcForm lng={heLang} font={'ar'} dir={'rtr'} txtAlign={'right'}/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
