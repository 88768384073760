import { useContext, useEffect, useState } from 'react';
import ThemeContext from '../../contexts/ThemeContext';
import './ThemeSwitcher.css'

const ThemeSwitcher = () => {

	const { changeTheme, setTheme } = useContext(ThemeContext)
	const [isChecked,setIsChecked] = useState(false);

	const handleChange = () => {
		setIsChecked(!isChecked)
		changeTheme()
	}

	// change theme automatically bnased on time
	const currentHour = new Date().getHours();

	// Define the start and end times for nighttime (in 24-hour format)
	const nightStartHour = 18; // 6pm
	const nightEndHour = 6; // 6am

	useEffect(() => {

		if (currentHour >= nightStartHour || currentHour < nightEndHour){
		setTheme({
			mode:"dark",
			bodyBg:"#323233",
			cardsBg:"#1E1E1E",
			cardsBgHover:"#171717",
			text:"#fff",
			qb:"#F47A1B",
		  })
		  setIsChecked(v => !v)
		}
	
	},[setTheme, currentHour] )

	// Check if the current hour is within the nighttime range
		


	return (

		<div className='ThemeSwitcher'>
			<input className='ThemeSwitcher-input' onChange={e => handleChange()} type="checkbox" id="toggle" checked={isChecked}/>
			<label className='ThemeSwitcher-label' htmlFor="toggle"></label>
		</div>

	)
}

export default ThemeSwitcher   