import { useContext} from 'react'
import PricingContext from '../../contexts/PricingContext'
import './MonthlyYearlyBtn.css'


const MonthlyYearlyBtn = ({plan:{monthly,yearly}}) => { 

const {pricingPlan, changePricingPlan} = useContext(PricingContext)


return(

<div className='MonthlyYearlyBtn'>
<label className={`toggler ${pricingPlan === 'yearly' ? 'toggler--is-active' : ''}`}>{yearly}</label>
  <div className="toggle">
    <input type="checkbox" id="switcher" className="check" onChange={e => changePricingPlan(pricingPlan)}/>
    <b className="b switch"></b>
  </div>
  <label className={`toggler ${pricingPlan === 'monthly'  ? 'toggler--is-active' : ''}`}>{monthly}</label>
</div>
)
}

export default MonthlyYearlyBtn