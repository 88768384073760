import { Map, Marker } from "pigeon-maps"

export function MyMap({cur}) {

  const ilLocation = [32.775440, 35.221979]
  const uaeLocation = [25.276987, 55.296249]
  const map = cur === "$" ? uaeLocation : ilLocation

  return (
    <Map  defaultCenter={map} defaultZoom={10}>
      <Marker width={30} anchor={map} />
    </Map>
  )
}