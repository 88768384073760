import './IndividualServices.css'
import { endpoint } from '../../utils/endpoint'
import ThemeContext from '../../contexts/ThemeContext';
import { useContext } from 'react';
import {$Rate, ilNum, uaeNum} from '../../utils/links'


const IndividualServices = ({ lng, dir, cur, wspLink }) => {

    const { IndividualServices } = lng
    const { preTitle, afterTitle, gradientText, desc, services } = IndividualServices
    const { theme } = useContext(ThemeContext);

    const svcs = {
        "logo": "6823828424296487",
        "post": "9733302246740852",
        "story": "9733302246740852",
        "posts": "",
        "storys": "",
        "other": "9281199678616963",
        "nfc": "6607533219265358",
        "menu": "6283075818481521",
        "landing": "6411159215597106",
        "svcs": "6044058065723482",
        "sales": "6348827781867550",
        "develop": "",
        "vids": "",
        "motionvids": ""
    }

    const handleClick = (svc) => {
        // window.open(`${wspLink}/form`,'_blank')
        let qbNum = cur === "$" ? uaeNum : ilNum
        let link = svcs[svc] === "" ? wspLink : `https://wa.me/p/${svcs[svc]}/${qbNum}`

        window.open(link, '_blank')
    }
    return (

        <div className='IndividualServices' >
            <div style={{ padding: '0px 40px' }}>
                <h2 style={{ direction: dir }}>{preTitle} <span className='gradientText'>{gradientText}</span> {afterTitle}</h2>
                <p>{desc}</p>
            </div>
            <div className="slider-container" style={{ backgroundColor: theme.cardsBg, direction: dir }}>
                {services.map((svc, index) => (
                    <div key={index} className="square" style={{ backgroundImage: `url(${endpoint}${svc.img})` }}
                        onClick={e => handleClick(svc.code)}>
                        <div className="square-content">
                            <div className="square-overlay"></div>
                            <h3 className="square-title">
                                {svc.name}
                            </h3>
                            <p className="square-price gradientText">
                                {svc.prePrice} {cur === '₪' ? `${svc.price}₪` : `${Math.floor((svc.price / $Rate).toFixed(0) / 12.9) * 10} $`}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default IndividualServices