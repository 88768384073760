import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {LanguageProvider,PricingProvider, ThemeProvider} from './contexts'

ReactDOM.render(
  <React.StrictMode>
    <PricingProvider>
    <LanguageProvider>
    <ThemeProvider>
    <App />
    </ThemeProvider>
    </LanguageProvider>
    </PricingProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
