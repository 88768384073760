import { useContext } from 'react';
import { IndividualServices, MonthlyYearlyBtn, PlanCard, SectionHeading } from '../../Components';
import PricingContext from '../../contexts/PricingContext';
import './Plans.css'


const Plans = ({ language, dir, txtAlign, cur, lngCode, wspLink }) => {
    const { p, h2, webAndSocial, onlySocial, onlyWeb, planTypes } = language.plans;
    const { currentPlan } = useContext(PricingContext);

    const plan = (plan) => {
        switch (plan) {
            case "website":
                return onlyWeb
            case "both":
                return webAndSocial
            case "socialmedia":
                return onlySocial

            default:
                break;
        }
    }

    return (

        <div className='Plans flex-center section' id={language.menu[1][1]}>
            <SectionHeading p={p} h2={h2} />
            <MonthlyYearlyBtn plan={language.plans} />
            <div className="cards">
                {plan(currentPlan).map((card, i) => (
                    <PlanCard wspLink={wspLink} plan={language.plans} card={card} planTypes={planTypes} cur={cur} txtAlign={txtAlign} key={i} dir={dir} />
                ))}
            </div>
            <div className="anchor" id={language.menu[2][1]}></div>
            <IndividualServices wspLink={wspLink} cur={cur} lng={language} dir={dir} lngCode={lngCode} />
        </div>

    )
}

export default Plans