import { useContext } from 'react';
import { endpoint } from '../../utils/endpoint'
import { useNavigate } from 'react-router-dom'
import LanguageContext from "../../contexts/LanguageContext"
import './BottomCircleMenu.css'
import { igLink } from '../../utils/links';

const BottomCircleMenu = ({phone, cur}) => {

  const navigate = useNavigate();
  const { language, changeLang } = useContext(LanguageContext);

  const changeLangTo = (lng) => {
    navigate(`/${lng}`)
    changeLang(lng)
  }

  const url = `${endpoint}${process.env.PUBLIC_URL}/assets`
  return (

    <nav className="menux">
      <input type="checkbox" href="#" className="menu-open" name="menu-open" id="menu-open" />
      <label className="menu-open-button" htmlFor="menu-open">
        <span className="lines line-1"></span>
        <span className="lines line-2"></span>
        <span className="lines line-3"></span>
      </label>
      {cur === "$" ? 
      <button onClick={() => language === 'en' ? changeLangTo('ae') : changeLangTo('en')} className={`menu-item ${language === 'en' ?'ar':'en'}-lang`}> <img className='flag-icon' src={language === 'en' ? `${url}/ar.svg` : `${url}/eng.svg`} alt="QB Media" /></button>
      :  
      <button onClick={() => language === 'ar' ? changeLangTo('he') : changeLangTo('ar')} className={`menu-item ${language === 'ar' ?'ar':'he'}-lang`}> <img className='flag-icon' src={language === 'ar' ? `${url}/he.svg` : `${url}/ar.svg`} alt="QB Media" /></button>
    }
      <button onClick={() => window.open(`https://api.whatsapp.com/send?phone=${phone}`,'_blank')} className={`menu-item`}><img className='flag-icon' src={`${url}/wsp.svg`} alt="QB Media" /></button>
      <a href={igLink} target={'_blank'} rel="noreferrer" className="menu-item instagram"> <img className='flag-icon' src={`${url}/ig.svg`} alt="QB Media" /></a>
    </nav>


  )
}

export default BottomCircleMenu