import './ToolCard.css'
import { getIcon } from '../../utils/iconGetter'
import { useContext, useState } from 'react'
import LanguageContext from '../../contexts/LanguageContext'
import ThemeContext from '../../contexts/ThemeContext'

const ToolCard = ({ title, desc, icon, dir, txtAlign }) => {

    const { language } = useContext(LanguageContext)
    const { theme } = useContext(ThemeContext);
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    return (

        <div className='ToolCard' style={{ backgroundColor: isHover ? (theme.mode === "dark" ? "black" : theme.text) : theme.cardsBg, 
            color: isHover ? (theme.mode === "dark" ? "vanil" : theme.cardsBg) : theme.text }} onMouseLeave={e => handleMouseLeave()} onMouseEnter={e => handleMouseEnter()}
            data-aos={`fade-${dir}`} data-aos-duration="1500">
            <div className="toolIcon" style={{textAlign:txtAlign}}>{getIcon(icon)}</div>
            <h2 className={`tc-${language}`} style={{textAlign:txtAlign}}>{title}</h2>
            <p className={`tc-${language}`} style={{textAlign:txtAlign}}>{desc}</p>
        </div>

    )
}

export default ToolCard